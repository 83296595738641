import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../../services/OrderService";

export const getAllFolderCount = createAsyncThunk(
  "/order/getAllFolderCount",
  async (userName) => {
    console.log('userName: ', userName)
    try {
      const response = await OrderService.getAllFolderCount(userName);
      const data = await response?.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getCountryOrders = createAsyncThunk(
  "/order/getCountryOrders",
  async (searchData) => {
    try {
      const response = await OrderService.getCountryOrders(searchData);
      const data = await response?.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getOrdersList = createAsyncThunk(
  "/order/getOrdersList",
  async (orderData) => {
    try {
      const response = await OrderService.getOrdersList({ orderData });
      const data = response?.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getProcessedByDetails = createAsyncThunk("/order/getProcessedByDetails", async (dateData) => {
  try { 
    const response = await OrderService.getProcessedByDetails(dateData);
    const data = response?.data;
    return data;
  } catch(error) {
    return error?.response?.data;
  }
});

export const GetDefencePackPdf = createAsyncThunk(
  "/admin/GetDefencePackPdf",
  async (defenceData) => {
    try {
      const response = await OrderService.GetDefencePackPdf(defenceData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchOrder = createAsyncThunk(
  "/order/searchOrder",
  async (searchData) => {
    try {
      const response = await OrderService.searchOrder(searchData);
      const data = await response?.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const deleteOrdersData = createAsyncThunk(
  "/order/deleteOrdersData",
  async (data) => {
    try {
      await OrderService.deleteOrdersData(data);
      return data.orderId;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const assignOrdersData = createAsyncThunk(
  "/order/deleteOrdersData",
  async (data) => {
    try {
      await OrderService.assignOrdersData(data);
      return data.orderId;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const sendRefundMail = createAsyncThunk(
  "/admin/sendRefundMail",
  async (refundData) => {
    try {
      const response = await OrderService.sendRefundMail({
        orderId: refundData,
      });
      const data = await response?.data;
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const moveOrderStatus = createAsyncThunk(
  "/order/moveOrderStatus",
  async (orderStatus) => {
    try {
      const response = await OrderService.moveOrderStatus(orderStatus);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderDetailsByOrderId = createAsyncThunk(
  "/order/getOrderDetailsByOrderId",
  async (orderId) => {
    try {
      const response = await OrderService.getOrderDetailsByOrderId(orderId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const defencePack = createAsyncThunk(
  "/admin/defencePack",
  async (defenceData) => {
    try {
      const response = await OrderService.defencePack(defenceData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const uploadVisaDocument = createAsyncThunk(
  "/admin/uploadVisaDocument",
  async (uploadData) => {
    try {
      const response = await OrderService.uploadVisaDocument(uploadData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const resendMail = createAsyncThunk(
  "/admin/resend-mail",
  async (mailData) => {
    try {
      const response = await OrderService.resendMail(mailData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendInvoice = createAsyncThunk("admin/sendInvoice",async (orderId) => {
  try {
    const response = await OrderService.sendInvoice(orderId);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
});

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    error: null,
    mailCount: null,
    ordersList: [],
    orderDetails: [],
    processOrdersList: [],
    orderInfo: {},
    phoneNumberList: [],
    showSideBar: false,
    selectedData: [],
    count: {},
    countryOrder: [],
    orderId: null,
  },
  reducers: {
    toggleSidebar: (state) => ({
      ...state,
      showSideBar: !state.showSideBar,
    }),
    toggleNavbar: (state) => ({
      ...state,
      showNavbar: !state.showNavbar,
    }),
    handleChange: (state, action) => {
      const allDeleteData = action.payload.selectedRows;
      const data = allDeleteData.map(({ id, status: status }) => ({
        id,
        status,
      }));
      return {
        ...state,
        selectedData: data,
      };
    },
    cleanOrdersList: (state) => ({
      ordersList: [],
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllFolderCount.pending, (state) => {
      console.log("pending");
      state.loading = true;
    });
    builder.addCase(getAllFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action?.payload?.data;
    });
    builder.addCase(getAllFolderCount.rejected, (state, action) => {
      console.log("rejected");
      state.loading = false;
    });
    builder.addCase(getCountryOrders.pending, (state) => {
      console.log("pending");
      state.loading = true;
    });
    builder.addCase(getCountryOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.countryOrder = action?.payload?.data;
    });
    builder.addCase(getCountryOrders.rejected, (state, action) => {
      console.log("rejected");
      state.loading = false;
    });
    builder.addCase(getOrdersList.pending, (state) => {
      console.log("pending");
      state.loading = true;
    });
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      console.log("fulfilled");
      state.loading = false;
      state.ordersList = action?.payload?.data;
    });
    builder.addCase(getOrdersList.rejected, (state) => {
      console.log("rejected");
      state.loading = false;
    });
    builder.addCase(getOrderDetailsByOrderId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderDetailsByOrderId.fulfilled, (state, action) => {
      state.loading = false;
      state.orderDetails = action?.payload?.data;
    });
    builder.addCase(getOrderDetailsByOrderId.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadVisaDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadVisaDocument.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadVisaDocument.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteOrdersData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrdersData.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersList = state.ordersList.filter(
        (item) => action?.payload.indexOf(item.id) === -1
      );
    });
    builder.addCase(deleteOrdersData.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(searchOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersList = action?.payload?.data;
    });
    builder.addCase(searchOrder.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendRefundMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendRefundMail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendRefundMail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getProcessedByDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProcessedByDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.processedOrderDetails = action?.payload?.data
    });
    builder.addCase(getProcessedByDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    
  },
});

export const {
  toggleSidebar,
  handleChange,
  handlePerRowsChange,
  handlePageChange,
  cleanOrdersList,
  toggleNavbar,
} = orderSlice.actions;
export default orderSlice.reducer;
